import { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { getLocationPreference } from "../util/preferences";

import { locations } from "../util/locations";

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

const useLocationDetection = () => {
  const [detectedLocation, setDetectedLocation] = useState();
  const { query } = useRouter();

  useEffect(() => {
    if (detectedLocation) return;

    // Find stored location and restore that object from config to get latest config
    const storedLocation = getLocationPreference();
    const foundMatch = storedLocation && locations.find(
      (loc) => storedLocation.id === loc.id
    );
    if (foundMatch) setDetectedLocation(foundMatch);

    const { location } = query;
    if (location) {
      const locationMatch = locations.find(
        (loc) => loc.urlQuery.toLowerCase() === location.toLowerCase()
      );
      if (locationMatch) setDetectedLocation(locationMatch);
    }
  }, [query]);

  return detectedLocation;
};

export const detectLocationUsingGPS = (callback, errorHandler) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      let bestChoice;
      locations.map((location) => {
        const distance = getDistanceFromLatLonInKm(
          position.coords.latitude,
          position.coords.longitude,
          location.latitude,
          location.longitude
        );
        if (!bestChoice || distance < bestChoice.distance) {
          bestChoice = {
            location,
            distance,
          };
        }
      });
      callback(bestChoice.location);
    }, errorHandler);
  }
};

export default useLocationDetection;
